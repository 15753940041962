import { BrandDetailResponse } from "~/server/types/gift";
import styles from "../gift_container.module.scss";
import { createSignal, JSX } from "solid-js";
import { Spacer } from "~/widgets/spacer";
import { formatDateLong } from "~/utils/date";
import { GiftBackground } from "../gift_background";
import { BorderGradient, Card } from "../gift_card";
import { GiftBranding } from "../gift_footer";

export type GiftExpiredProps = {
  brand: BrandDetailResponse;
  amount: number;
  expiryDate: string;
  getGiftVoucherSectionComponent?: () => JSX.Element;
};

export function GiftExpired(props: GiftExpiredProps) {
  const [isShadowVisible, setIsShadowVisible] = createSignal(true);
  return (
    <div class="relative">
      <GiftBackground shadow={isShadowVisible} />
      <div class="absolute inset-0 z-20 flex max-h-screen flex-col items-center justify-center overflow-hidden text-baseTertiaryLight">
        <div class="relative flex w-full max-w-[390px] flex-col items-center text-center  ">
          <span class="text-h3 font-medium text-baseTertiaryLight">
            This card has expired
          </span>
          <Spacer height={4} />
          <span class="text-normal text-textNormal">
            {`On ${formatDateLong(new Date(props.expiryDate))}`}
          </span>
          <Spacer height={32} />
          <div
            classList={{
              [styles.cardInfinteBounce]: true,
            }}
            class="w-full "
          >
            <div class="flex w-full items-center justify-center">
              <div class="flex w-full max-w-[432px] flex-col items-center justify-center  px-[32px] ">
                <BorderGradient>
                  <Card
                    brand={props.brand}
                    amount={props.amount}
                    isPreview={false}
                    getGiftVoucherSectionComponent={
                      props.getGiftVoucherSectionComponent
                    }
                    isExpired={true}
                  />
                </BorderGradient>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="absolute bottom-4 left-0 right-0 flex  justify-center">
        <GiftBranding />
      </div>
    </div>
  );
}
