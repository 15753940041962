import { Spacer } from "~/widgets/spacer";
import { GiftBackground } from "../gift_background";
import { Accessor, Match, Switch } from "solid-js";
import { ThreeDotLoader } from "~/widgets/button";
import { ButtonDark } from "../verification/components";
import { Job, JobWrapper } from "~/utils/job";
import { GiftBoxRouteData } from "~/routes/gift-box/[giftingKey]";
import { HubbleEvent, webEventManager } from "~/data/events";
import { openIntercom } from "~/utils/third_party/intercom";
import { getGiftPrefixedIntercomProps } from "./gift_intercom_props";

export function GiftBoxFallback(props: {
  fetchGiftBoxRouteJob: JobWrapper<GiftBoxRouteData>;
  errorMessage: Accessor<string | undefined>;
}) {
  return (
    <div class="relative h-screen">
      <GiftBackground />
      <div class="absolute inset-0  flex  flex-col items-center  justify-center ">
        <Spacer height={36} />
        <Switch>
          <Match when={props.fetchGiftBoxRouteJob.jobState() == "error"}>
            <GiftBoxErrorState />
          </Match>
          <Match when={props.fetchGiftBoxRouteJob.jobState() == "running"}>
            <ThreeDotLoader color="0xffffff" />
          </Match>
        </Switch>
      </div>
    </div>
  );

  function GiftBoxErrorState() {
    return (
      <>
        <span class="px-10 text-center text-h4 font-medium text-baseTertiaryLight">
          {props.errorMessage()}
        </span>
        <Spacer height={24} />
        <ButtonDark
          class="w-full max-w-[75%] sm:max-w-[300px]"
          isLoading={() => props.fetchGiftBoxRouteJob.jobState() == "running"}
          onClick={() => {
            props.fetchGiftBoxRouteJob.run();
          }}
        >
          {" "}
          Retry
        </ButtonDark>
        <Spacer height={16} />
        <span class="text-medium text-baseTertiaryLight">
          Need help with anything?{" "}
          <u
            class="cursor-pointer"
            onClick={() => {
              webEventManager.sendEvent(HubbleEvent.GIFT_CONTACT_SUPPORT, {
                state: "error",
                message: props.errorMessage(),
              });
              openIntercom(
                getGiftPrefixedIntercomProps({
                  state: "error",
                  message: props.errorMessage(),
                })
              );
            }}
          >
            Contact support
          </u>
        </span>
      </>
    );
  }
}
